<template>
    <ion-page>
        <ion-content :scroll-events="false">
            <div class="column">
                <div class="grow">
                    <div class="column">
                        <div
                            class="xs4"
                            style="position: relative; overflow: hidden"
                        >
                            <div class="header-style"></div>
                            <div class="column justify-center align-center">
                                <i
                                    class="
                                        mdi mdi-check-circle-outline
                                        white--text
                                    "
                                    style="font-size: 100px"
                                ></i>
                                <div
                                    class="font-18 font-bold white--text"
                                    style="text-transform: uppercase"
                                >
                                    {{ model.Title }}
                                </div>
                            </div>
                        </div>
                        <div class="xs8">
                            <div
                                class="
                                    container
                                    font-18 font-bold
                                    text-xs-center
                                "
                            >
                                {{ model.Message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="container-md mb-1">
                        <ion-button
                            color="primary"
                            expand="block"
                            class="full-width mb-3"
                            @click="
                                $router.push({
                                    path: '/tabs/LenhDienTu',
                                })
                            "
                        >
                            VỀ DANH SÁCH LỆNH
                        </ion-button>
                        <!-- <ion-button
                            color="primary"
                            expand="block"
                            fill="outline"
                            class="full-width"
                            @click="
                                $router.replace({
                                    path: '/DNVT-Quan-Ly-Lenh',
                                })
                            "
                            >VỀ DANH SÁCH
                        </ion-button> -->
                        <ion-button
                            color="primary"
                            expand="block"
                            fill="outline"
                            class="full-width"
                            @click="
                                $router.push({
                                    path: '/',
                                })
                            "
                        >
                            VỀ TRANG CHỦ
                        </ion-button>
                    </div>
                </div>
            </div></ion-content
        >
    </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonIcon } from "@ionic/vue";
export default {
    components: {
        IonContent,
        IonPage,
        IonButton,
        IonIcon,
    },
    data() {
        return {
            model: {
                Title: "",
                Message: "",
            },
        };
    },
    mounted() {
        let data = this.$route.query;
        if (data) {
            this.model.Title = data.title;
            this.model.Message = data.message;
        }
    },
};
</script>
<style lang="scss" scoped>
.header-style {
    width: 150%;
    position: absolute;
    left: -26%;
    height: 100%;
    background: linear-gradient(
        to bottom right,
        $primary,
        rgb(3 169 244 / 34%)
    );
    bottom: 1px;
    border-bottom-left-radius: 61%;
    border-bottom-right-radius: 61%;
}
.divider {
    border-bottom: 1px solid $border;
}
</style>

